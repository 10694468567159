import React from 'react';
import Layout from '../components/Layout';
import Title from '../components/Title/Title';
import Tabs from '../components/Tabs/Tabs';
import DynamicContent from '../components/DynamicContent/DynamicContent';
import Banner from '../components/Banner/Banner';
import References from '../components/References/References';
import CtaDescList from '../components/CtaDescList/CtaDescList';
import DownloadButton from '../components/DownloadButton/DownloadButton';
import Footnotes from '../components/Footnotes/Footnotes';

import DosingFirstLineDesktop from '../images/dosing/dosingFirstLineDesktop.inline.svg';
import DosingFirstLineMobile from '../images/dosing/dosingFirstLineMobile.inline.svg';
import DosingChartMobile from '../images/dosing/dosingChartMobile.inline.svg';
import DosingChart from '../images/dosing/dosingChart.inline.svg';
import DosingSubsequestDesktop from '../images/dosing/dosingSubsequestDesktop.inline.svg';
import DosingSubsequestMobile from '../images/dosing/dosingSubsequestMobile.inline.svg';
import DosingAdverseReactionsDesktop from '../images/dosing/adverseReactionsDesktop.inline.svg';
import DosingAdverseReactionsMobile from '../images/dosing/adverseReactionsMobile.inline.svg';

import { GtagHead } from '../components/GTAG';
export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Dosing & Administration | LOQTORZI® (toripalimab-tpzi)</title>
      <meta name='description'
        content='LOQTORZI® is administered with cisplatin and gemcitabine for the first 6 cycles, then given as monotherapy.'/>
    </>
  )
}

export default function Dosing() {
  const tabPreparationAndStorage = [
    {
      title: <span>Preparation</span>,
      text: <section>
        <h3 className='text-blue-900'>Preparation for IV infusion</h3>
        <ul className='bullet-list big bullet-list--yellow mt-[1.1rem] tablet:mt-[2.25rem]'>
          <li>
            Visually inspect the solution for particulate matter and discoloration.
            The solution is clear to slightly
            opalescent, colorless to slightly
            yellow. Discard the vial if visible particles are observed
            <br/><br/>
          </li>
          <li>
            Withdraw the required volume of LOQTORZI<sup>®</sup> and inject slowly into a 100-mL or 250-mL infusion bag containing 0.9% Sodium Chloride Injection, USP.
            <strong> Mix diluted solution by gentle inversion.</strong> Do not shake. The final concentration of the diluted solution should be between 1 mg/mL and 3 mg/mL
            <br/><br/>
          </li>
          <li>
            LOQTORZI<sup>®</sup> is compatible with polypropylene infusion bags and infusion sets with 0.2 or 0.22 micron
            in-line filter
            <br/><br/>
          </li>
          <li>Discard any unused portion left in the&nbsp;vial</li>
        </ul>
        <Footnotes className='mt-[2.6rem]' items={[
          {
            bullet: '',
            text: <span>IV=intravenous.</span>
          }
        ]} />
      </section>
    },
    {
      title: <span>Storage</span>,
      text: <section>
        <h3 className='text-blue-900'>Storage of diluted solution<br className='tablet:hidden'/> for infusion</h3>
        <div className='mt-[1.1rem] leading-[1.3] tablet:mt-[2.25rem]'>
          LOQTORZI<sup>®</sup> does not contain
          a preservative. If the diluted solution
          is not administered immediately,
          store either:
        </div>
        <ul className='bullet-list big bullet-list--yellow mt-[1.18rem]'>
          <li>
            At room temperature, 20 °C to 25 °C (68 °F to 77 °F), for no more than 8 hours from the time of dilution to the completion of the infusion. Discard diluted solution stored at room temperature after 8 hours
          </li>
        </ul>
        <br/><br/>
        <span className='pl-[1.5ex] font-MontserratBoldItalic'>OR</span>
        <br/><br/>
        <ul className='bullet-list big bullet-list--yellow'>
          <li>
            Refrigerated at 2 °C to 8 °C (36 °F to 46 °F) for no more than 24 hours from the time of dilution to the completion of the infusion. If refrigerated, allow the diluted solution to come to room temperature prior to administration.
            Discard the refrigerated diluted solution after 24 hours
            <br/><br/>
            Do not freeze.
          </li>
        </ul>
      </section>
    }
  ];

  const pageReferences = [
    <span>LOQTORZI<sup>®</sup> (toripalimab-tpzi) Prescribing Information. Redwood City, CA: Coherus BioSciences, Inc.</span>
  ];

  return (
    <Layout>
      <Title pageName={'Dosing'}>
        Dosing <br className='tablet:hidden' />
        & Administration<sup>1</sup>
      </Title>

      <DynamicContent header={<h2>Dosing AND administration for first-line treatment</h2>}>
        <div className='wrapperContent'>
          <div className='paragraph mt-[2rem] !mb-[1.5rem]'>
            <div className='mt-[2rem] font-BarlowCondensedBold text-[2.2rem] text-blue-500 tablet:mt-[3rem]'>
              Recommended dosage
            </div>
          </div>

          <DosingChartMobile
            className={'w-full m-auto h-auto mb-[2rem] tablet:hidden'}
          />
          <DosingChart
            className={'hidden w-full m-auto h-auto mb-[2rem] tablet:block'}
          />
          <Footnotes className={'mt-[2.17rem]'} items={[{
            bullet:'*',
            text: <span>LOQTORZI<sup>®</sup> is indicated, in combination with cisplatin and gemcitabine, for the first-line treatment of adults with metastatic or with recurrent, locally advanced NPC. Refer to the Prescribing Information for cisplatin and gemcitabine for recommended dosing information.</span>
          }]} />
          <div className='paragraph mt-[2rem] !mb-[1.5rem]'>
            <div className='mt-[2rem] font-BarlowCondensedBold text-[2.2rem] text-blue-500 tablet:mt-[3rem]'>
              In JUPITER-02, LOQTORZI<sup>®</sup> was administered:
            </div>
          </div>
          <DosingFirstLineMobile
            className={'w-full m-auto h-auto mb-[2rem] tablet:hidden'}
          />
          <DosingFirstLineDesktop
            className={'hidden w-full h-auto m-auto mb-[2rem] tablet:block'}
          />
        </div>
        <Banner>
          <span>
            LOQTORZI<sup>®</sup> was administered chemo free after the first 6 cycles
          </span>
        </Banner>
        <div className='wrapperContent'>
          <div className='font-MontserratBold text-[1.7rem] mt-[3rem]'>Administration</div>
          <ul className='bullet-list big mt-[1.5rem]'>
            <li>LOQTORZI<sup>®</sup> IV infusion details:
              <ul className='bullet-list-dash leading-[1.3]'>
                <li>Administer diluted solution intravenously via infusion pump using an in-line aseptic filter (0.2 or 0.22 micron)</li>
                <li>First infusion: Infuse over at least 60 minutes</li>
                <li>Subsequent infusions: If no infusion-related reactions occurred during the first infusion, subsequent infusions may be administered over 30 minutes</li>
                <li>Do not co-administer other drugs through the same IV line</li>
                <li>When administered on the same day as chemotherapy, LOQTORZI<sup>®</sup> should be administered prior to chemotherapy</li>
                <li>Refer to the Prescribing Information for cisplatin and gemcitabine for recommended dosing information</li>
              </ul>
            </li>
          </ul>

          <Footnotes className={'mt-[2.17rem]'} items={[{
            bullet:'',
            text: <span>IV=intravenous; NPC=nasopharyngeal carcinoma; R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
          }]} />
        </div>
      </DynamicContent>

      <DynamicContent header={<h2>Dosing and administration for subsequent treatment</h2>}>
        <div className='wrapperContent'>
          <DosingSubsequestMobile
            className={'tablet:hidden w-full h-auto m-auto my-[3rem]'}
          />
          <DosingSubsequestDesktop
            className={'hidden tablet:block w-full h-auto m-auto my-[3rem]'}
          />
        </div>
        <Banner>
          <span>
            LOQTORZI<sup>®</sup> is administered as a chemo-free regimen
          </span>
        </Banner>
        <div className='wrapperContent'>
          <div className='paragraph mt-[3rem]'>{<span>Patients should receive LOQTORZI<sup>®</sup> until disease progression or unacceptable toxicity.</span>}</div>
          <div className='font-MontserratBold text-[1.7rem]'>Administration</div>
          <ul className='bullet-list big mt-[1.3rem]'>
            <li>First infusion: Infuse over at least 60 minutes</li>
            <li>Subsequent infusions: If no infusion-related reactions occurred during the first infusion, subsequent infusions may be administered over 30 minutes</li>
            <li>Do not co-administer other drugs through the same IV line</li>
          </ul>

          <Footnotes className={'mt-[2.17rem]'} items={[{
            bullet:'',
            text: <span>IV=intravenous.</span>
          }]} />

        </div>
      </DynamicContent>


      <DynamicContent
        header={ <h2>Recommended dose modifications for adverse reactions</h2> }>
        <div className='wrapperContent'>
          <DosingAdverseReactionsMobile
            className={'w-full h-auto m-auto my-[3rem] tablet:hidden'}
          />
          <DosingAdverseReactionsDesktop
            className={'hidden w-full h-auto m-auto my-[3rem] tablet:block'}
          />
          <Footnotes items={[
              {
                bullet: '*',
                text: <span>Based on National Cancer Institute (NCI) Common Terminology for Adverse Events (CTCAE) version 5.0.<sup>1</sup></span>
              },
              {
                bullet: <sup>†</sup>,
                text: <span>Resume LOQTORZI<sup>®</sup> in patients with complete or partial resolution to Grade 0 or 1 after corticosteroid taper. Permanently discontinue if no complete or partial resolution within 12 weeks of initiating steroids or inability to reduce prednisone to 10 mg per day or less (or equivalent) within 12 weeks of initiating steroids.<sup>1</sup></span>
              },
              {
                bullet: <sup>‡</sup>,
                text: <span>If AST and ALT are less than or equal to ULN at baseline in patients with liver involvement, withhold or permanently discontinue LOQTORZI<sup>®</sup> based on recommendations for hepatitis with no liver involvement.<sup>1</sup></span>
              },
              {
                bullet: '',
                text: <span>ALT=alanine aminotransferase; AST=aspartate aminotransferase; DRESS=drug rash with eosinophilia and systemic symptoms; SJS=Stevens-Johnson syndrome; TEN=toxic epidermal necrolysis; ULN=upper limit of normal.</span>
              }
            ]} />
        </div>
      </DynamicContent>

      <DynamicContent header={<h2>Preparation and storage</h2>}>
        <Tabs className='mt-[2rem] tablet:ml-[3rem]' content={ tabPreparationAndStorage } isBorderLeft={false} />
      </DynamicContent>

      <DynamicContent divider={false}>
        <div className='wrapperContent pt-[3rem] pb-[5rem]'>
          <div className='subtitle text-center'>Dosing and Administration Guide</div>
          <div className='paragraph !text-center'>A helpful guide that includes dosing and administration information for LOQTORZI<sup>®</sup>.</div>
          <DownloadButton file={'/pdf/loqtorzi-dosing-and-administration-guide.pdf'} className='downloadableResource m-auto' title={<>Download the LOQTORZI<sup>®</sup> Dosing and Administration Guide</>}>
            <span>Download guide</span>
          </DownloadButton>
        </div>
      </DynamicContent>

      <References items={pageReferences} border={true}></References>

      <CtaDescList content={[{
          cta: {
            text: <>LOQTORZI<sup>®</sup> at a glance</>,
            url:'/loqtorzi-at-a-glance/'
          },
          text: <div>This module offers you quick <br className='desktop:hidden' />access to key information <br className='desktop:hidden' />about LOQTORZI<sup>®</sup> <br className='hidden large:block' />
            to help you <br className='desktop:hidden' />support your patients.</div>
        }, {
          cta: {
            text: <span>Interactive <br/>
              Prescribing Information </span>,
            url:'/loqtorzi-prescribing-information/'
          },
          text: <div>Explore LOQTORZI<sup>®</sup> data in the
            <br className='desktop:hidden' /> interactive Prescribing Information.</div>
        },
        {
          cta: {
            text: 'Get Updates',
            url: '/get-updates/'
          },
          text: <div>Register to receive the latest <br className='desktop:hidden' />information and announcements <br className='desktop:hidden' />about LOQTORZI<sup>®</sup>.</div>
        }
      ]} />

    </Layout>
  );
}